<template>
    <div class="cookie-pop-up" v-if="showPopup">
        <div class="cookie-content">
            <div class="cookie-body" v-html="trans.get('cookie.cookie_body')"></div>
            <div class="cookie-footer">
                <button type="button" class="hover:text-cookie-primary" @click="toggleModal(true)" v-text="trans.get('cookie.settings')"></button>
                <button type="button" class="ml-3 rounded-2xl bg-cookie-primary text-white px-4 py-2 border border-cookie-primary hover:bg-white hover:text-cookie-primary" @click="acceptRequired"  v-text="trans.get('cookie.accept_only_required')"></button>
                <button type="button" class="ml-3 rounded-2xl bg-cookie-primary text-white px-4 py-2 border border-cookie-primary hover:bg-white hover:text-cookie-primary" @click="acceptAll" v-text="trans.get('cookie.accept_all')"></button>
            </div>
        </div>

        <div class="cookie-modal" v-show="showModal">
            <div class="cookie-modal-content">
                <div class="cookie-modal-header">
                    <h4 class="font-semibold text-xl" v-text="trans.get('cookie.modal.title')"></h4>
                    <button @click="toggleModal(false)" class="text-xl">&times;</button>
                </div>
                <div class="cookie-modal-body">
                    <div v-html="trans.get('cookie.cookie_body')"></div>

                    <div class="cookie-accordion mt-4">
                        <button
                            type="button"
                            :class="['cookie-accordion-header', {
                                '_open': accordion.required
                            }]"
                            @click="toggleAccordion('required')"
                        >
                            <span v-text="trans.get('cookie.modal.required_cookies')"></span>

                            <svg version="1.2" preserveAspectRatio="none" viewBox="0 0 24 24" class="icon-wedge-svg" data-id="e9b3c566e8c14cfea38af128759b91a3" style="opacity: 1; mix-blend-mode: normal; fill: rgb(51, 51, 51); width: 32px; height: 32px;"><path xmlns:default="http://www.w3.org/2000/svg" id="angle-down" d="M17.2,9.84c0-0.09-0.04-0.18-0.1-0.24l-0.52-0.52c-0.13-0.13-0.33-0.14-0.47-0.01c0,0-0.01,0.01-0.01,0.01  l-4.1,4.1l-4.09-4.1C7.78,8.94,7.57,8.94,7.44,9.06c0,0-0.01,0.01-0.01,0.01L6.91,9.6c-0.13,0.13-0.14,0.33-0.01,0.47  c0,0,0.01,0.01,0.01,0.01l4.85,4.85c0.13,0.13,0.33,0.14,0.47,0.01c0,0,0.01-0.01,0.01-0.01l4.85-4.85c0.06-0.06,0.1-0.15,0.1-0.24  l0,0H17.2z" style="fill: rgb(51, 51, 51);"></path></svg>
                        </button>
                        <div :class="['cookie-accordion-content', {
                            '_open': accordion.required
                        }]">
                            <div class="cookie-accordion-content-body" v-html="trans.get('cookie.modal.required_cookies_body')"></div>
                        </div>
                    </div>

                    <div class="cookie-accordion">
                        <button
                            type="button"
                            :class="['cookie-accordion-header', {
                                '_open': accordion.functional
                            }]"
                            @click="toggleAccordion('functional')"
                        >
                            <span class="flex items-center">
                                <span :class="{
                                    'w-2 h-2 bg-green-500 rounded-full mr-2 block': settings.functional.enabled,
                                    'w-2 h-2 bg-red-500 rounded-full mr-2 block': !settings.functional.enabled,
                                }"></span>
                                <span v-text="trans.get('cookie.modal.functional_cookies')"></span>
                            </span>

                            <svg version="1.2" preserveAspectRatio="none" viewBox="0 0 24 24" class="icon-wedge-svg" data-id="e9b3c566e8c14cfea38af128759b91a3" style="opacity: 1; mix-blend-mode: normal; fill: rgb(51, 51, 51); width: 32px; height: 32px;"><path xmlns:default="http://www.w3.org/2000/svg" id="angle-down" d="M17.2,9.84c0-0.09-0.04-0.18-0.1-0.24l-0.52-0.52c-0.13-0.13-0.33-0.14-0.47-0.01c0,0-0.01,0.01-0.01,0.01  l-4.1,4.1l-4.09-4.1C7.78,8.94,7.57,8.94,7.44,9.06c0,0-0.01,0.01-0.01,0.01L6.91,9.6c-0.13,0.13-0.14,0.33-0.01,0.47  c0,0,0.01,0.01,0.01,0.01l4.85,4.85c0.13,0.13,0.33,0.14,0.47,0.01c0,0,0.01-0.01,0.01-0.01l4.85-4.85c0.06-0.06,0.1-0.15,0.1-0.24  l0,0H17.2z" style="fill: rgb(51, 51, 51);"></path></svg>
                        </button>

                        <div :class="['cookie-accordion-content', {
                            '_open': accordion.functional
                        }]">
                            <div class="cookie-accordion-content-body">
                                <p v-text="trans.get('cookie.modal.functional_cookies_body_1')"></p>
                                <div class="w-full flex mt-2">
                                    <div class="w-1/3">
                                        <div class="switch">
                                            <input id="functional" type="checkbox" v-model="settings.functional.enabled" />
                                            <label for="functional" class="label">
                                                {{ trans.get('cookie.no') }}
                                                <span class="_switch-control"></span>
                                                {{ trans.get('cookie.yes') }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="w-2/3" v-html="trans.get('cookie.modal.functional_cookies_body_2')"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="cookie-accordion">
                        <button
                            type="button"
                            :class="['cookie-accordion-header', {
                                '_open': accordion.marketing
                            }]"
                            @click="toggleAccordion('marketing')"
                        >
                            <span class="flex items-center">
                                <span :class="{
                                    'w-2 h-2 bg-green-500 rounded-full mr-2 block': settings.marketing.enabled,
                                    'w-2 h-2 bg-red-500 rounded-full mr-2 block': !settings.marketing.enabled,
                                }"></span>
                                <span v-text="trans.get('cookie.modal.marketing_cookies')"></span>
                            </span>

                            <svg version="1.2" preserveAspectRatio="none" viewBox="0 0 24 24" class="icon-wedge-svg" data-id="e9b3c566e8c14cfea38af128759b91a3" style="opacity: 1; mix-blend-mode: normal; fill: rgb(51, 51, 51); width: 32px; height: 32px;"><path xmlns:default="http://www.w3.org/2000/svg" id="angle-down" d="M17.2,9.84c0-0.09-0.04-0.18-0.1-0.24l-0.52-0.52c-0.13-0.13-0.33-0.14-0.47-0.01c0,0-0.01,0.01-0.01,0.01  l-4.1,4.1l-4.09-4.1C7.78,8.94,7.57,8.94,7.44,9.06c0,0-0.01,0.01-0.01,0.01L6.91,9.6c-0.13,0.13-0.14,0.33-0.01,0.47  c0,0,0.01,0.01,0.01,0.01l4.85,4.85c0.13,0.13,0.33,0.14,0.47,0.01c0,0,0.01-0.01,0.01-0.01l4.85-4.85c0.06-0.06,0.1-0.15,0.1-0.24  l0,0H17.2z" style="fill: rgb(51, 51, 51);"></path></svg>
                        </button>

                        <div :class="['cookie-accordion-content', {
                            '_open': accordion.marketing
                        }]">
                            <div class="cookie-accordion-content-body">
                                <p v-text="trans.get('cookie.modal.marketing_cookies_body_1')"></p>
                                <div class="w-full flex mt-2">
                                    <div class="w-1/3">
                                        <div class="switch">
                                            <input id="marketing" type="checkbox" v-model="settings.marketing.enabled" />
                                            <label for="marketing" class="label">
                                                {{ trans.get('cookie.no') }}
                                                <span class="_switch-control"></span>
                                                {{ trans.get('cookie.yes') }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="w-2/3" v-html="trans.get('cookie.modal.marketing_cookies_body_2')"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cookie-modal-footer">
                    <button type="button" class="mr-3 rounded-2xl bg-cookie-primary text-white px-4 py-2 border border-primary hover:bg-white hover:text-cookie-primary" @click="acceptCurrent" v-text="trans.get('cookie.save_settings')"></button>
                    <button type="button" class="rounded-2xl bg-cookie-primary text-white px-4 py-2 border border-primary hover:bg-white hover:text-cookie-primary" @click="acceptAll" v-text="trans.get('cookie.accept_all')"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CookiePopUp',

    props: {
        accepted: {
            required: true,
        }
    },

    data() {
        return {
            showPopup: false,
            showModal: false,
            settings: {
                required: {
                    enabled: true,
                },
                functional: {
                    enabled: true,
                },
                marketing: {
                    enabled: true,
                },
            },
            accordion: {
                required: false,
                functional: false,
                marketing: false,
            }
        }
    },

    created() {
        if (this.accepted) {
            return;
        }

        this.showPopup = true;
    },

    methods: {
        toggleModal(showModal) {
            this.showModal = showModal;
        },

        toggleAccordion(key) {
            this.accordion[key] = !this.accordion[key]
        },

        acceptCurrent() {
            this.setCookie('cookie_consent', JSON.stringify(this.settings));

            this.showPopup = false;
        },

        acceptRequired() {
            this.settings.required.enabled = true;
            this.settings.functional.enabled = false;
            this.settings.marketing.enabled = false;

            this.setCookie('cookie_consent', JSON.stringify(this.settings));

            this.showPopup = false;
        },

        acceptAll() {
            this.settings.required.enabled = true;
            this.settings.functional.enabled = true;
            this.settings.marketing.enabled = true;

            this.setCookie('cookie_consent', JSON.stringify(this.settings));

            this.showPopup = false;
        },

        setCookie(name, value) {
            var d = new Date();
            var durationDays = 365;

            d.setTime(d.getTime() + durationDays * 24 * 60 * 60 * 1000);

            var expires = "expires=" + d.toUTCString();

            document.cookie = name + "=" + value + ";" + expires + ";path=/";
        },
    }
}
</script>

<style lang="scss" scoped>
@tailwind base;

@tailwind components;

@tailwind utilities;

@import "./../../scss/mixins/mixins_site";

.cookie-pop-up {
    @apply fixed max-w-full right-2 rounded-lg shadow-cookie bottom-2 left-2 w-auto bg-white z-modal;

    @screen md {
        @apply max-w-popup w-full left-auto;
    }

    .cookie-content {
        @apply p-4 text-sm;

        p {
            @apply text-sm;
        }

        ._link {
            @apply text-cookie-primary;
        }

        .cookie-body {}
        .cookie-footer {
            @apply flex items-center mt-4;

            button {
                @include transition-all;
            }
        }
    }

    // Modal window
    .cookie-modal {
        @apply fixed inset-0 bg-black-65 flex items-center justify-center;

        .cookie-modal-content {
            @apply bg-cookie-bg rounded-lg px-6 py-4 flex flex-col max-w-modal w-full max-h-screen-80 overflow-auto;

            ._link {
                @apply text-cookie-primary;
            }

            .cookie-modal-header {
                @apply flex w-full justify-between items-center;
            }

            .cookie-modal-body {
                @apply mt-2;

                .cookie-accordion {
                    @apply overflow-hidden -mx-6;

                    .cookie-accordion-header {
                        @apply bg-cookie-header-bg p-5 w-full border-t border-b border-black-10 flex items-center justify-between;

                        svg {
                            @include transition-all;
                        }

                        &._open {
                            svg {
                                transform: rotate(-90deg);
                            }
                        }
                    }

                    .cookie-accordion-content {
                        @apply max-h-0 bg-white;
                        @include transition-all;

                        &._open {
                            @apply max-h-panel;
                        }

                        .cookie-accordion-content-body {
                            @apply p-4;

                            p, ul, li {
                                @apply text-sm;
                            }
                        }

                        .switch {
                            input {
                                @apply hidden;

                                &:checked {
                                    & + .label {
                                        ._switch-control {
                                            &:before {
                                                @apply bg-green-500;
                                                transform: translateX(50%);
                                            }
                                        }
                                    }
                                }
                            }

                            .label {
                                @apply flex items-center select-none text-sm;

                                ._switch-control {
                                    @apply relative block w-8 h-4 bg-switch border border-switch-border rounded-full mx-2;

                                    &:before {
                                        @apply block w-5 h-5 rounded-full bg-switch-off absolute top-0 bottom-0 m-auto transition-all duration-300;
                                        content: '';
                                        left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .cookie-modal-footer {
            @apply mt-4 flex justify-end;

            button {
                @include transition-all;
            }
        }
    }
}
</style>
