// console.log('app.js started');

/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the body of the page. From here, you may begin adding components to
 * the application, or feel free to tweak this setup for your needs.
 */

// Vue.component('example', require('./components/Example.vue'));
//
// const app = new Vue({
//     el: '#app'
// });

$(function () {

  // get current scroll offset
  function getCurrentScroll() {
      return window.pageYOffset || document.documentElement.scrollTop;
  }

  // highlight the top nav as scrolling occurs
  var animateHeader = 50,
      menu = $('.navbar-fixed-top'),
      navbarToggle = $('.navbar-toggle'),
      navbar = $('.navbar-collapse'),
      productDownloadLink = $('.product .download-link'),
      triggerLang = $('.navbar-link.navbar-languages'),
      menuLang = '.languages-menu';

  // check scroll offset position while scrolling
  $(window).scroll(function () {
      if (getCurrentScroll() >= animateHeader) {
          menu.addClass('moving');
      }
      else {
          menu.removeClass('moving');
      }
  })

  if(getCurrentScroll() >= animateHeader) {
      menu.addClass('moving');
  }

  triggerLang.on('click', function () {
      $(this).children(menuLang).toggleClass('is-active');
  });

  // productDownloadLink.on('click', function(event) {
  //     event.preventDefault();
  //     alert('Downloading...');
  // });

  // show navigation
  navbarToggle.on('click', function (event) {
      event.preventDefault();
      navbar.toggleClass('is-visible');
      $('body').toggleClass('overflow-hidden');
  })

  var submenuParent = $('.has-submenu');
  var submenuAll = $('.submenu');
  var submenuIsActive = false;

  submenuParent.on('click', function (e) {
      e.preventDefault();

      var el = $(this),
          submenu = $(el.data('submenu'));

      submenuAll.not(submenu).removeClass('is-visible');
      submenuParent.not(el).removeClass('hovered');

      // for click event
      if (! el.hasClass('hovered')) {
          submenu.addClass('is-visible');
          el.addClass('hovered');
      } else {
          submenu.removeClass('is-visible');
          el.removeClass('hovered');
      }
  });

  // submenuParent.hover(function (e) {
  //     var el = $(this),
  //         submenu = $(el.data('submenu'));
  //
  //     submenuAll.removeClass('is-visible');
  //     submenuParent.removeClass('hovered');
  //
  //     el.addClass('hovered');
  //     submenu.addClass('is-visible');
  // }, function (e) {
  //     var el = $(this),
  //         submenu = $(el.data('submenu'));
  //
  //     setTimeout(function () {
  //         if (submenuIsActive === false) {
  //             el.removeClass('hovered');
  //             submenu.removeClass('is-visible');
  //         }
  //     }, 10);
  //
  // });

  // set item's list elements
  // there are two types of list
  // slick carousel and grid
  var productsListCarousel = $('#products .products-list.style-1');
  var productsListGrid = $('#products .products-list.style-2');
  var filesListGrid = $('#files .files-list');
  var productsFilterBtn = $('.products-filter li');

  // instantiate a slick carousel
  productsListCarousel.slick({
      appendArrows: '.products-nav',
      prevArrow: '<a href="#" class="slick-prev"><img src="/img/koestlin-arrow.svg"></a>',
      nextArrow: '<a href="#" class="slick-next"><img src="/img/koestlin-arrow.svg"></a>',
      cssEase: 'ease-in-out',
      fade: false,
      lazyload: 'ondemand',
      slidesToShow: 4,
      slidesToScroll: 4,
      draggable: true,
      responsive: [{
          breakpoint: 320,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
          },
          breakpoint: 640,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
          }
      }]
  });

  // filter items
  productsFilterBtn.on('click', function () {
      var li = $(this),
          _btn = li.parents('.products-filter-dropdown').find('.btn-select'),
          _type = li.parents('ul').data('type'),
          _isCarousel = _type == 'carousel' ? true : false,
          _isGrid = _type == 'grid' ? true : false;

      if (_isGrid) {
          productsFilterBtn.removeClass('active');
          _btn.find('.text').text(li.text());
      }
      else if (_isCarousel) {
          var _filterBy = li.data('category'),
              _productsFilter = productsListCarousel;

          productsFilterBtn.removeClass('active');
          li.addClass('active');
          _btn.find('.text').text(li.text());
          _productsFilter.slick('slickUnfilter');

          if (_filterBy !== 'all') {
              // console.log('animate - fade out current slides ...');
              _productsFilter.slick('slickFilter', '.' + _filterBy);
          }
      }
  });

  // MixItUp
  filesListGrid.mixItUp({
      animation: {
          duration: 250,
          effects: 'fade scale(0.96) stagger(0ms)',
          easing: 'ease-in-out'
      },
      selectors: {
          target: '.item',
          filter: productsFilterBtn
      },
      callbacks: {
          onMixEnd: function (state) {
              // console.log(state);
          }
      }
  });

  productsListGrid.mixItUp({
      animation: {
          duration: 250,
          effects: 'fade scale(0.96) stagger(0ms)',
          easing: 'ease-in-out'
      },
      selectors: {
          target: '.product',
          filter: productsFilterBtn
      },
      callbacks: {
          onMixEnd: function (state) {
              // console.log(state);
          }
      }
  });

  var modalToggle = $('.modal-toggle'),
      modalClose = $('.modal-overlay .overlay-close');

  modalToggle.on('click', function () {
      var btn = $(this),
          $id = btn.data('id'),
          overlay = $('#overlay-' + $id),
          $content = overlay.find('.overlay-content-inner > *'),
          $i = 1,
          $delay = 250;

      $('body').addClass('overflow-hidden');
      overlay.addClass('is-visible');

      $content.each(function () {
          var _col = $(this);

          setTimeout(function () {
              _col.addClass('is-visible');
          }, $delay);
          $delay += 125;
      })
  })

  modalClose.on('click', function () {
		  closeOverlay();
	})

	$(document).keyup(function (event) {
		  if (event.which == '27') closeOverlay();
	})

  function closeOverlay() {
      $('body').removeClass('overflow-hidden');
      $('.modal-overlay, .modal-overlay *').removeClass('is-visible');
  }

  function loadPage() {
      loadingBox.addClass('has-loaded');
      app.addClass('has-loaded');
      $('body').removeClass('overflow-hidden');
  }

  //

  var app = $('#app'),
      loadingBox = $('#loading-page'),
      loadingLogo = loadingBox.children('img'),
      loadingIcon = loadingBox.children('.loading-icon');

  setTimeout(loadPage, 125);

});
